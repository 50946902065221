.mainEstDiv {
  width: 55%;
  margin: 0 auto;
  margin-top: 3%;
  transition-property: width;
  /* transition-duration: 1s; */
}

.mainEstDivAnimateLeft {
  transition: 1s;
  width: 45%;
  float: left;
  /* margin-left: 0; */
}

/* #searchForm {
  width: 100%;
  margin-bottom: 3%;
} */

#printTHIS {
  border-radius: 0.25rem;
  border: #454d55 solid 1px;
  /* padding: .6%; */
  color: #bba3d0;
  font-weight: bold;
  /* width: 20%; */
}

#addEstBtn {
  border-radius: 0.25rem;
  width: 12%;
  color: #bba3d0;
  font-weight: bold;
  border: #454d55 solid 1px;
  margin-left: 3%;
  padding: 0.6%;
}

#addExtraBttn {
  border-radius: 0.25rem;
  width: 10%;
  color: #bba3d0;
  font-weight: bold;
  border: #454d55 solid 1px;
  padding: 0.6%;
}

#estPart {
  width: 75%;
  padding: 0.6%;
  border-radius: 0.25rem;
  border: #454d55 solid 1px;
}

#estQTY {
  padding: 0.6%;
  width: 7%;
  margin-left: 3%;
  border-radius: 0.25rem;
  border: #454d55 solid 1px;
}

#totalSpan {
  margin-right: 1.5%;
  font-weight: bold;
  margin-top: 1%;
}

#bottomEstDiv {
  /* min-width: 180px; */
  width: 16vw;
  float: right;
  /* margin-top: 5%; */
  border: #343a40 solid 2px;
  padding: 2%;
  border-radius: 0.25rem;
}
.addINPUT {
  width: 25%;
  border-radius: 0.25rem;
  border: #454d55 solid 1px;
  margin-right: 5%;
  padding: 0.6%;
}

.addINPUT2 {
  width: 55%;
  border-radius: 0.25rem;
  border: #454d55 solid 1px;
  margin-right: 5%;
  padding: 0.6%;
}

.totalInput {
  width: 57%;
  border-radius: 0.25rem;
  border: #454d55 solid 1px;
  padding: 0.6%;
  margin-right: 2%;
}

#download-spreadsheet {
  border-radius: 0.25rem;
  width: 101px;
  margin-top: 5px;
  font-weight: bold;
  border: #454d55 solid 1px;
  /* margin-left: 2%; */
  /* padding: 0.6%; */
  background-color: #bba3d0;
  color: white;
}

/* .modal-dialog {
  max-width: none;
  padding-left: 9%;
} */
